<template>
<div class="pt-5 d-flex flex-column align-items-center mb-4 px-5">
	<div class="signup-box d-flex flex-column align-items-start">
		<button class="btn btn-transparent"
						@click="$router.go(-1)">
			<i class="fas fa-chevron-left mr-2" />กลับไป
		</button>
		<div class="signup-logo mb-4 align-self-center" />

		<h1 class="mb-4 align-self-center">
			แบบฟอร์มขอสมัครสมาชิก ({{ domain }})
		</h1>

		<h5 class="mb-4 align-self-center text-muted">
			กรุณากรอกและส่งฟอร์มนี้เพื่อขอสมัครสมาชิก
		</h5>

		<div class="font-thaisans">
		<div class="row py-3 border-b">
			<div class="col-12">
				<h4 class="mb-3">ชื่อ/ที่อยู่</h4>
				<div class="form-row">
					<FormInlineSelect
						class="col-12"
						label="ประเภท"
						:btn-class-list="['red', 'orange']"
						:options="LISTS.CONTACT_TYPES"
						v-model="form.contact_type" />
					<FormContactNameInput
						class="col-12 p-0"
						:contact-type="form.contact_type"
						v-model="form.name" />
					<FormAddressInput
						class="col-12 p-0"
						v-model="form.address" />
				</div>
			</div>
		</div>

		<transition name="fade-no-delay">
			<div class="row py-3 border-b">
				<div class="col-12">
					<h4 class="mb-3">ชื่อ/ที่อยู่ ภาษาอังกฤษ</h4>
					<div class="form-row">
						<div class="form-group col-12 col-sm-9 col-md-8">
							<checkbox
								label="เพิ่มข้อมูลภาษาอังกฤษ"
								label-class="label-lg"
								secondary-label="(ใช้เมื่อต้องการออกรายงานเป็นภาษาอังกฤษ)"
								@input="on_english_toggle($event)"
								v-model="include_english" />
						</div>
					</div>
					<div  v-if="include_english"
								class="form-row">
						<FormContactNameInput
							class="col-12 p-0"
							:contact-type="form.contact_type"
							english
							v-model="form.name_en" />
						<FormAddressInput
							class="col-12 p-0"
							english
							v-model="form.address_en" />
					</div>
				</div>
			</div>
		</transition>

		<div class="row py-3 border-b">
			<div class="col-12">
				<h4 class="mb-3">ข้อมูลติดต่อ</h4>
				<div class="form-row">
					<FormInput
						class="col"
						label="อีเมล (ใช้สำหรับ login)"
						type="text"
						required
						v-model="form.email" />
					<FormPhoneInput
						class="col"
						required
						v-model="form.phone" />
				</div>
			</div>
		</div>

		<div class="row py-4 font-cu">
			<div class="col-12">
				<div class="form-row">
					<div class="form-group col-12">
						<button class="btn btn-primary btn-block btn-lg"
										@click="email_signup_request()"
										:disabled="submitting || submitted">
							<template v-if="submitting">
								<LoadingAnimation />
							</template>
							<template v-else-if="submitted">
								<i class="fas fa-check btn-inner-icon"></i>
								แบบฟอร์มขอสมัครสมาชิก ถูกส่งเรียบร้อยแล้ว
							</template>
							<template v-else>
								<i class="fas fa-paper-plane btn-inner-icon"></i>
								ส่งแบบฟอร์มขอสมัครสมาชิก
							</template>
						</button>
						<ErrorBox v-if="form_error" :msg="form_error" />
					</div>
					<div v-if="submitted" class="form-group col-12">
						<button class="btn btn-secondary btn-block btn-lg"
										@click="$router.go(-1)">
							<i class="fas fa-chevron-left mr-2" />กลับไป หน้าหลัก
						</button>
					</div>
				</div>
			</div>
		</div>
		
	</div>
		<!-- <h1 class="mb-4 align-self-center">
			ขั้นตอนการสมัครสมาชิก
		</h1>

		<div class="w-100 d-flex flex-column align-items-center">
			<div class="questionaire-form">
				<ol>
					<li>ส่งข้อมูลดังนี้ทางอีเมลมาที่ <span class="text-primary email-box">cuvdl.thailand@gmail.com</span></li>
					<ul>
						<li>ชื่อ-นามสกุล หรือ ชื่อ ฟาร์ม/องค์กร ที่ท่านเป็นตัวแทน</li>
						<h6 class="text-muted my-2 mr-5">หากท่านต้องการให้ออก Report ผลการทดสอบเป็นภาษาอังกฤษ กรุณาให้ข้อมูลภาษาอังกฤษมาด้วย</h6>
						<li>อีเมล (ใช้ในการ login และแจ้งเตือน)</li>
						<li>เบอร์โทรศัพท์ติดต่อ</li>
					</ul>
					<li>เมื่อทางเราสร้าง Account ให้แล้ว ท่านจะได้รับลิ้งค์ทางอีเมลเพื่อเปิดการใช้งาน Account</li>
					<h6 class="text-muted my-2 mr-5">กรุณาเช็ค Spam, Junk, Trash ในอีเมลในกรณีที่ไม่เห็นอีเมลใน Inbox</h6>
					<li>เมื่อท่านเข้าไปที่ลิ้งค์ ระบบจะให้ท่านตั้งรหัสผ่านให้ Account ของท่าน</li>
					<li>หลังจากท่านตั้งรหัสผ่านแล้ว ท่านจะสามารถ Login เข้าสู่ระบบและทำการส่งตัวอย่างได้ทันที</li>
				</ol>
			</div>
		</div> -->
	</div>
	<Modal  modal-id="signup-success-modal"
					modal-dialog-class="modal-md modal-dialog-centered">
		<template #modal-header>
			<h3>
				<i class="fas fa-check text-success mr-2"></i>
				แบบฟอร์มขอสมัครสมาชิก ({{ domain }}) สำเร็จ
			</h3>
		</template>
		<template #modal-body>
			<h4 class="mb-2">เจ้าหน้าที่จะพิจารณาคำขอสมัครสมาชิก และติดต่อท่านกลับไปทางอีเมล</h4>
			<h4 class="mb-2">เมื่อเจ้าหน้าที่อนุมัติคำขอสมัครสมาชิก ท่านจะได้รับอีเมลที่มี link เปิดใช้งาน</h4>
			<h4 class="mb-2">เมื่อได้รับอีเมล กรุณาเข้าไปที่ link เพื่อตั้งรหัสผ่านและเปิดการใช้งาน Account</h4>
			<div class="form-row mt-4">
				<div class="form-group col mb-0">
					<button type="button"
									class="btn btn-secondary btn-block"
									data-dismiss="modal">
						กลับไป
					</button>
				</div>
			</div>
		</template>
	</Modal>
</div>
</template>

<script>
import $ from 'jquery'
import { GetCurrentDomainDisplayName } from '@/api'
import { EMAIL_SIGNUP_REQUEST } from '@/graphql/misc'
import { ContactType } from '@/enum'

export default {
	name: 'signup',
	computed: {
		domain () {
			return GetCurrentDomainDisplayName()
		}
	},
	data () {
		return {
			form_error: null,
			submitting: false,
			submitted: false,
			include_english: false,
			form: {
				contact_type: ContactType.PERSON,
				email: null,
				phone: null,
				name: null,
				address: null,
				name_en: null,
				address_en: null,
			},
		}
	},
	methods: {
		on_english_toggle (english) {
			if (!english) {
				this.form.name_en = ''
				this.form.nameAddress = ''
			}
		},
		valid_form () {
			const form_filled = this.form.email && this.form.phone && this.form.name && this.form.address
			const english_info_valid = !this.include_english || (this.form.name_en && this.form.address_en)
			return form_filled && english_info_valid
		},
		clear_form () {
			this.email = null
			this.phone = null
			this.name = null
			this.address = null
			this.name_en = null
			this.address_en = null
		},
		async email_signup_request () {
			if (!this.valid_form()) {
				this.form_error = 'กรุณากรอกข้อมูลให้ครบ'
				return
			} else {
				this.form_error = null
			}
			this.submitting = true
			try {
				let res = await this.$apollo.mutate({
					mutation: EMAIL_SIGNUP_REQUEST,
					variables: {
						contact_type: this.form.contact_type,
						email: this.form.email,
						phone: this.form.phone,
						name: this.form.name,
						address: this.form.address,
						name_en: this.form.name_en || '--',
						address_en: this.form.address_en || '--',
					}
				})
				this.submitted = true
				this.submitting = false
				$('#signup-success-modal').modal('show')
				this.clear_form()
			} catch (err) {
				this.submitting = false
				this.form_error = 'ระบบขัดข้อง กรุณาลองใหม่ในภายหลัง'
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.signup-box {
	max-width: 750px;
}
.signup-logo {
	@include logo;
	width: 150px;
	height: 150px;
}
.org-row:not(:last-child) {
	border-bottom: 1px solid $accent;
}
.email-box {
	border-radius: $border-radius;
	background: $accent-light;
	padding: .25em .7em;
	margin-left: .5em;
}
.questionaire-form {
	width: 600px;
}
ol {
	font-weight: bold;
	font-size: 1.5rem;
	li {
		&::marker {
			color: $primary;
			font-size: 1.3em;
		}
		margin: .5em 0;
	}
}
ul {
	list-style-type: circle;
	padding-left: 3em;
	margin: 1em 0;
	font-weight: bold;
	font-size: 1.3rem;
}
</style>