<template>
<div class="d-flex flex-column justify-content-center align-items-center fill-height">
	<div class="login-form">
		<button class="btn btn-transparent mb-3"
						@click="$router.push({ name: 'home' })">
			<i class="fas fa-chevron-left mr-2" />กลับไป
		</button>
		<div class="d-flex w-100 justify-content-center">
			<div class="login-logo" />
		</div>
		<div class="form-row">
			<FormInlineSelect
				class="col-12 mb-4"
				label="เลือกสาขาห้องแลบที่ใช้บริการ"
				btn-class="btn-lg"
				label-class="mb-2"
				:btn-class-list="['pink', 'orange']"
				:options="domain_display_names"
				v-model="selected_domain"
				@change="on_domain_change()" />
			<FormInput
				class="col-12"
				input-class="form-control-lg"
				type="text"
				label="อีเมล"
				v-model="login_form.email"
				@keyup.enter="on_login()" />
			<FormInput
				class="col-12"
				input-class="form-control-lg"
				type="password"
				label="รหัสผ่าน"
				v-model="login_form.password"
				@keyup.enter="on_login()" />
		</div>
		<div class="form-row mb-3">
			<div class="form-group col-12 d-flex justify-content-end">
				<button class="btn btn-transparent btn-sm text-muted"
								@click="forgot_password()">
					ลืมรหัสผ่าน
				</button>
			</div>
		</div>
		<div v-if="error" class="form-row">
			<div class="form-group col-12">
				<ErrorBox msg="รหัสผ่าน หรือ อีเมล ไม่ถูกต้อง" />
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-12">
				<button class="btn btn-primary btn-lg btn-block loading"
								:disabled="loading"
								@click="on_login()">
					<template v-if="loading">
						<LoadingAnimation />
					</template>
					<template v-else>
						<i class="fas fa-sign-in-alt btn-inner-icon"></i> เข้าสู่ระบบ
					</template>
				</button>
			</div>
			<div class="form-group col-12">
				<router-link  :to="{name: 'signup'}"
											tag="a"
											class="btn btn-secondary btn-lg btn-block">
					<i class="fas fa-user-plus btn-inner-icon"></i> ขอสมัครสมาชิก
				</router-link>
			</div>
		</div>
	</div>

	<Modal  modal-id="forgot-password-modal">
		<template #modal-header>
			<h3 class="text-primary">
				<i class="fas fa-question mr-1" />
				ลืมรหัสผ่าน
			</h3>
		</template>
		<template #modal-body>
			<h4 class="text-dark mb-3">
				กรุณากรอกข้อมูลเพื่อยืนยันตัวตน หากข้อมูลถูกต้อง ท่านจะได้รับลิ้งค์เปลี่ยนรหัสผ่านทางอีเมล
			</h4>
			<h4 class="text-medium mb-1">ใส่อีเมลที่ใช้ login ของ{{ selected_domain }}</h4>
			<div class="form-row">
				<FormInput
					class="col-12"
					type="text"
					input-class="form-control-lg"
					v-model="forgot_pass_email" />
			</div>
			<div class="form-row mt-3">
				<div class="form-group col-4 mb-0">
					<button type="button"
									class="btn btn-secondary btn-block"
									data-dismiss="modal">
						ยกเลิก
					</button>
				</div>
				<div class="form-group col-8 mb-0">
					<button type="button" class="btn btn-primary btn-block loading"
									:disabled="forgot_pass_loading"
									@click="submit_forgot_password()">
						<template v-if="forgot_pass_loading">
							<LoadingAnimation />
						</template>
						<template v-else>
							<i class="fa fa-envelope btn-inner-icon"></i>ส่งลิ้งค์เปลี่ยนรหัสผ่านทางอีเมล
						</template>
					</button>
				</div>
			</div>
		</template>
	</Modal>
	
</div>
</template>

<script>
import $ from 'jquery'
import {
	DOMAIN_TRANSLATION,
	DOMAIN_DISPLAY_NAMES,
	GetCurrentDomain,
	SetCurrentDomain,
} from '@/api'
import { get_last_route } from '@/router'
import { on_login } from '@/jwt-auth'
import { LOGIN, FORGOT_PASSWORD } from '@/graphql/login'

export default {
	name: 'login',
	data () {
		return {
			loading: false,
			error: false,
			login_form: {
				email: null,
				password: null,
				stay_logged_in: true
			},
			forgot_pass_email: null,
			forgot_pass_loading: false,
			domain_display_names: Object.values(DOMAIN_DISPLAY_NAMES),
			selected_domain: null,
		}
	},
	beforeMount () {
		const current_domain = GetCurrentDomain()
		this.selected_domain = DOMAIN_DISPLAY_NAMES[current_domain]
	},
	methods: {
		async on_login () {
			const { email, password } = this.login_form
			if (!email || !password) {
				this.error = true
				return
			}
			try {
				this.loading = true
				const res = await this.$apollo.mutate({
					mutation: LOGIN,
					variables: {
						email,
						password
					}
				})
				const jwt = res?.data?.login?.jwt
				if (jwt) {
					await on_login(
						this.$apollo.provider.defaultClient,
						jwt,
						this.login_form.stay_logged_in
					)
					const last_route_path = get_last_route()
					if (last_route_path) {
						this.$router.push(last_route_path)
					} else {
						this.$router.push({ name: 'submissions-list' })
					}

				} else {
					this.error = true
					this.loading = false
					this.login_form.email = null
					this.login_form.password = null
				}
			} catch (err) {
				this.error = true
				this.loading = false
			}
		},
		forgot_password () {
			$('#forgot-password-modal').modal('show')
		},
		async submit_forgot_password () {
			if (!this.forgot_pass_email) {
				return
			}
			this.forgot_pass_loading = true
			try {
				const res = await this.$apollo.mutate({
					mutation: FORGOT_PASSWORD,
					variables: {
						email: this.forgot_pass_email,
						domain: GetCurrentDomain(),
					}
				})
			} catch (err) {
				console.log(err)
			}
			this.forgot_pass_loading = false
			this.forgot_pass_email = ''
			$('#forgot-password-modal').modal('hide')
		},
		on_domain_change () {
			SetCurrentDomain(DOMAIN_TRANSLATION[this.selected_domain])
		}
	},
}
</script>

<style lang="scss" scoped>
.login-logo {
	@include logo;
	width: 250px;
	height: 250px;
	margin-bottom: 3em;
}
.login-form {
	@include media-breakpoint-up(lg) {
		width: 500px;
	}
	width: 425px;
}
</style>